<template>
  <div id="printMe" class="w-full p-2">
    <div class="w-full">
      <ul class="flex flex-col">
        <li v-for="(review, index) in performanceReviewComponent" :key="`row-${index}`">
          <template v-for="(row, component, r_index) in review">
            <component
              :key="`component-${r_index}`"
              :is="component"
              :rows="row"
              :scales="scales"
              :categories="categories"
              :current="current"
              :marks="marks"
              :is-assesment="false"
              :posted="posted"
              @current="(payload) => (current = payload)"
              @update="update"
              @error="error"
            />
          </template>
        </li>
      </ul>
      <button
        @click.prevent="confirm"
        class="bg-blue-600 hover:bg-blue-800 rounded px-2 py-1 text-white save-btn"
      >
        Speichern
      </button>
    </div>
  </div>
</template>

<script>
import _ from "lodash";
import { xhr } from "@/utils/axios";
import Confirm from "@/components/Dialogs/Confirm";


export default {
  name: "performance-review-prepare",
  props: [],
  components: {
    Confirm,
  },
  data() {
    return {
      breadcrumbs: {
        title: [
          {
            crumb: "Mitarbeitergespräche",
            link: "/personal/my-performance-evaluation",
          },
          {
            crumb: "Mitarbeitergespräch vorbereiten",
          },
        ],
        pageTitle: "Gespräch vorbereiten",
        print: true,
      },
      performanceReview: {},
      performanceReviewComponent: [],
      errors: {},
      scales: [],
      categories: [],
      current: null,
      reviewId: null,
      posted: false,
      completed: false,
    };
  },
  mounted() {

    this.init();

    this.$bus.$off("save-evaluation");
    this.$bus.$on("save-evaluation", this.save);
  },
  methods: {
    init() {
      this.updateLoader(true);
      this.updatePrinting(false);

      this.$emit("breadcrumbs", this.breadcrumbs);

      this.reviewId = this.$route.params.reviewId;

      xhr
        .get(`/performance-evaluation/setting/ratingscale/`)
        .then((response) => {
          this.scales = response.data.results;

          return xhr.get(`/performance-evaluation/${this.reviewId}/`);
        })
        .then((response) => {
          this.performanceReviewComponent = [response.data];
          this.performanceReview = response.data;

          return xhr.get(`/organisation/feedback/categorie`);
        })
        .then((response) => {
          this.categories = response.data.results;
        });

    },
    update(payload) {
      if (payload.value && payload.value[0]) {
        if (payload.value[0].rating !== 0) {
          this.performanceReview[payload.key] = payload.value;
        }
      }
    },
    error(payload) {      
      if (!payload.value) delete this.errors[payload.key];
      else
        this.errors[payload.key] = {
          value: payload.value,
          error: payload.error,
        };
    },
    confirm() {
      if (_.some(_.map(_.values(this.errors), "value"), Boolean)) {
        this.$bus.$emit("show-modal", {
          show: true,
          title: "Gespräch nicht komplett ausgefüllt",
          message:
            "Sind Sie sicher, dass Sie dieses Mitarbeiter Gespräch zwischenspeichern wollen?",
          event: "save-evaluation",
          maxWidth: "w-1/3",
          component: Confirm,
          type: "confirm",
          noRedirect: true,
        });
      } else {
        this.completed = true;
        this.save();
      }
    },
    save() {
      this.posted = true;

      xhr
        .patch(
          `/performance-evaluation/${this.reviewId}/?completed=${this.completed}`,
          this.performanceReview,
          {
            headers: {
              "Content-Type": "application/json",
            },
          }
        )
        .then(() => {
          this.$router.back();
        });
    },
    marks(id) {
      return id ? _.first(_.filter(this.scales, (scale) => scale.id === id)).name : null;
    },
  },
  updated() {
    this.$nextTick(() => {
      const components = this.$children;
      const rendered = components.every(component => component.$el.offsetWidth > 0);
      
      // Set the loading button to disabled if all child components have been rendered
      if (rendered) {
        this.updateLoader(false);
      } else {
        // Set the loading button to enabled if not all child components have been rendered
        this.updateLoader(true);
      }
    });
  },
  computed: {},
  watch: {
    value: {
      handler: function (n) {
        n && this.init();
      },
      deep: true,
    },
  },
};
</script>

<style></style>
